var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RowWrap', {
    staticClass: "CarsTable",
    class: [{
      'is-empty': _vm.mCars.length === 0
    }],
    attrs: {
      "width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.mCars.length > 0 ? _c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "parentHover": false,
            "items": {
              row: _vm.header
            },
            "index": -1
          },
          on: {
            "onClickColumn": function onClickColumn(e) {
              return _vm.handleHeaderClick(e);
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.mCars, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "items": car,
        "index": i,
        "isDropdown": ""
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), _vm.mCars.length === 0 ? _c('EmptyState', {
    attrs: {
      "message": "Keine Zuweisungen vorhanden"
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }