<template>
    <div class="Cars" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Übersicht Ihrer" highlight="Taxis" />
        <div class="Search">
            <Input placeholder="Fahrzeug suchen" keyName="query" @onKeyPress="handleSearch" />
        </div>
        <h5 class="">Aktuelle Zuweisungen</h5>
        <cars-table
            :cars="filteredCars.filter(car => car.currentInstallation)"
            @onCarClick="handleEditCar"
            @onCarHistoryClick="handleCarHistoryClick"
            @onCarInformationClick="handleCarHistoryClick"
            @onCarVisibilityChange="handleCarVisibilityChange"
        />
        <br />
        <br />
        <div v-if="filteredCars.filter(car => !car.currentInstallation)">
            <h5 class="">Vorherige Zuweisungen</h5>
            <cars-table
                :cars="filteredCars.filter(car => !car.currentInstallation)"
                @onCarClick="handleEditCar"
                @onCarInformationClick="handleCarHistoryClick"
                @onCarHistoryClick="handleCarHistoryClick"
                @onCarVisibilityChange="handleCarVisibilityChange"
            />
        </div>
        <Modal
            size="medium"
            :title="activeCar ? 'Fahrzeug bearbeiten' : 'Fahrzeug hinzufügen'"
            :show="modal.car.isActive"
            :isLoading="isFetchingData"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveCar }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleModalClose"
        >
            <Section title="Taxi Informationen">
                <div class="Input-Wrap">
                    <Input
                        keyName="licenseNumber"
                        label="Kennzeichen"
                        placeholder="Kennzeichen"
                        direction="vertical"
                        :isDisabled="true"
                        :value="activeCar && activeCar.licenseNumber ? activeCar.licenseNumber : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        :value="activeCar && activeCar.lastEichungAt ? activeCar.lastEichungAt : ''"
                        label="Eichung zuletzt am"
                        type="date"
                        placeholder="Eichung"
                        direction="vertical"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="lastTuvAt"
                        label="TÜV zuletzt am"
                        placeholder="TÜV"
                        type="date"
                        direction="vertical"
                        :value="activeCar && activeCar.lastTuvAt ? activeCar.lastTuvAt : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="lastBokraftAt"
                        label="BoKraft zuletzt am"
                        placeholder="BoKraft"
                        type="date"
                        direction="vertical"
                        :value="activeCar && activeCar.lastBokraftAt ? activeCar.lastBokraftAt : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="wheels"
                        label="KM Stand letzter Reifenwechsel"
                        placeholder="Reifen"
                        direction="vertical"
                        :value="activeCar && activeCar.wheels ? activeCar.wheels : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        :value="activeCar && activeCar.manufacturer ? activeCar.manufacturer : ''"
                        label="Hersteller"
                        :isDisabled="true"
                        placeholder="Hersteller"
                        direction="vertical"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        :value="activeCar && activeCar.lastOilChange ? activeCar.lastOilChange : ''"
                        label="KM-Stand letzter Ölwechse"
                        placeholder="KM-Stand letzter Ölwechse"
                        direction="vertical"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="model"
                        label="Modell"
                        placeholder="Modell"
                        :isDisabled="true"
                        direction="vertical"
                        :value="activeCar && activeCar.model ? activeCar.model : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        label="Seriennummer"
                        placeholder="Seriennummer"
                        direction="vertical"
                        :isDisabled="true"
                        :value="activeCar && activeCar.vin ? activeCar.vin : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="make"
                        label="Baujahr"
                        placeholder="Baujahr"
                        direction="vertical"
                        :isDisabled="true"
                        :value="activeCar && activeCar.make ? activeCar.make : ''"
                        @onKeyPress="handleKeypress"
                    />
                </div>
            </Section>
        </Modal>
    </div>
</template>

<script type="text/javascript">
import axios from 'axios';
import Fuse from 'fuse.js';
import Headline from '@/components/Headline';
import Input from '@/components/widgets/Input';
import Modal from '@/components/widgets/Modal';
import Section from '@/components/Section';
import CarsTable from './components/CarsTable.vue';

export default {
    name: 'Cars',
    components: {
        Headline,
        Input,
        Modal,
        Section,
        CarsTable,
    },
    data: () => ({
        cars: [],
        filteredCars: [],
        activeCar: null,
        isFetchingData: false,
        modal: {
            delete: {
                isActive: false,
            },
            car: {
                isActive: false,
            },
        },
    }),
    methods: {
        handleSearch({ query }) {
            if (query.length === 0) {
                this.filteredCars = this.cars;
                return;
            }
            const options = {
                minMatchCharLength: 2,
                threshold: Number.isNaN(Number(query)) ? 0.3 : 0.2,
                keys: ['installations.licenseNumber'],
            };
            const fuse = new Fuse(this.cars, options);
            const result = fuse.search(query);
            this.filteredCars = result.map(r => r.item);
        },
        handleCarHistoryClick({ car }) {
            this.$router.push({
                path: `/cars/${car.uuid}`,
                params: {
                    id: car.uuid,
                },
            });
        },
        handleModalClose() {
            this.modal.delete.isActive = false;
            this.modal.car.isActive = false;
            this.activeCar = null;
            this.currentIndex = null;
        },
        handleCarVisibilityChange({ car, isVisible }) {
            this.handleUpdateCar({ ...car, isVisible });
        },
        handleEditCar({ index }) {
            const car = this.cars[index];
            this.currentIndex = index;
            this.activeCar = car;
            this.modal.car.isActive = true;
        },
        async handleSaveCar() {
            await this.handleUpdateCar({ ...this.activeCar });
            await this.handleLoadCars();
            this.handleModalClose();
        },

        handleKeypress(car) {
            this.activeCar = { ...this.activeCar, ...car };
            console.log(this.activeCar);
        },
        async handleUpdateCar(car) {
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars/${car.id}`;
                const result = await axios.put(url, car, {
                    withCredentials: true,
                });
            } catch (error) {
                this.$toasted.show('Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
            }
        },
        async handleLoadCars() {
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.cars = result.data;
                this.filteredCars = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        this.handleLoadCars();
    },
};
</script>

<style lang="scss">
.Cars {
    @extend %contentWrapper;
    @extend %page;

    .Input-Wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 30px;
        margin-top: 40px;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            grid-template-columns: 1fr;
        }
    }

    .Search {
        border: solid 1px var(--color-text-inactive);
        border-radius: 4px;
        margin: 5px 10px 20px 0;
    }
}
</style>
