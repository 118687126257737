<template>
    <RowWrap width="auto" class="CarsTable" :class="[{ 'is-empty': mCars.length === 0 }]">
        <template #header>
            <Row
                v-if="mCars.length > 0"
                :parentHover="false"
                :items="{ row: header }"
                :key="-1"
                :index="-1"
                @onClickColumn="e => handleHeaderClick(e)"
                class="Row-Header"
            />
        </template>
        <Row
            v-for="(car, i) in mCars"
            :items="car"
            :key="i"
            :index="i"
            isDropdown
            @onHover="args => $emit('onRowHover', args)"
            @onLeave="args => $emit('onRowLeave', args)"
        />
        <EmptyState message="Keine Zuweisungen vorhanden" v-if="mCars.length === 0" />
    </RowWrap>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import Row from '@/components/Row';
import { dateToString } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'ItemTable',
    components: {
        RowWrap,
        Row,
        EmptyState,
    },
    props: {
        cars: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
            sortState: {
                column: 'Kennzeichen',
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Kennzeichen', value: '' },
                { name: 'Hersteller', value: '' },
                { name: 'Model', value: '' },
                { name: 'Verbaut Seit', value: '' },
                // { name: 'TÜV', value: '' },
                // { name: 'Ölwechsel', value: '' },
                { name: 'Information', value: '' },
            ]
                .map(header => {
                    let indicator = '';
                    if (this.sortState.column === header.name) {
                        indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                    }
                    return { ...header, name: header.name + indicator };
                })
                .filter(header => header?.name);
        },
        mCars() {
            return this.cars ? this.sortCars([...this.formatCars(this.cars)]) : [];
        },
    },
    methods: {
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortCars(arr) {
            try {
                const { column, ascending } = this.sortState;
                if (!column) return;
                const sortFn = (a, b) => {
                    const aValue = a.row.find(r => r?.name === column).rawValue;
                    const bValue = b.row.find(r => r?.name === column).rawValue;
                    if (aValue < bValue) return ascending ? -1 : 1;
                    if (aValue > bValue) return ascending ? 1 : -1;
                    return 0;
                };
                return arr.sort(sortFn);
            } catch (error) {
                return arr;
            }
        },
        handleCarClick(p) {
            const target = p.event.target;
            const isButton = target.classList.contains('Button') || target.type === 'button';
            if (isButton) return;

            this.$emit('onCarClick', p);
        },
        formatCars(cars) {
            return cars.map((car, index) => {
                const installations = car.installations;
                const currentInstallation = car.currentInstallation || installations[0];

                const installedAt = currentInstallation?.installedAt;
                const removedAt = currentInstallation?.removedAt;
                const installationString = `${dateToString(installedAt)} ${
                    removedAt ? 'bis ' + dateToString(removedAt) : ''
                }`;
                return {
                    row: [
                        {
                            name: 'Kennzeichen',
                            value: currentInstallation.licenseNumber,
                            rawValue: currentInstallation.licenseNumber,
                        },
                        // { name: 'Konzession', value: car.licenseNumber },
                        { name: 'Hersteller', value: car.make, rawValue: car.make },
                        { name: 'Model', value: car.model, rawValue: car.model },
                        {
                            name: 'Verbaut Seit',
                            value: installationString,
                            rawValue: installedAt,
                        },
                        // { name: 'TÜV', value: dateToString(car.lastTuvAt) || '' },
                        // {
                        //     name: 'Ölwechsel',
                        //     value: dateToString(car.lastOilChangeAt) || '',
                        // },

                        {
                            name: '',
                            props: {
                                keyName: 'isVisible',
                                value: 'Informationen',
                                variant: 'minimal',
                                size: 'small',
                                id: car.driverNumber,
                                onClick: () => this.$emit('onCarInformationClick', { car }),
                            },
                            component: 'Button',
                        },
                    ].filter(Boolean),
                    children: [
                        {
                            row: [
                                { name: 'Von', value: null },
                                { name: 'Bis.', value: null },
                                { name: 'Geräte Typ', value: null },
                                { name: 'Geräte ID', value: null },
                                { name: 'Kennzeichen', value: null },
                            ],
                        },
                        ...car.installations.map(installation => ({
                            row: [
                                {
                                    name: 'Von',
                                    value: dateToString(installation?.installedAt) || '',
                                },
                                { name: 'Bis', value: dateToString(installation?.removedAt) || '' },
                                { name: 'Geräte Typ', value: installation?.device?.type || '' },
                                {
                                    name: 'Geräte ID',
                                    value: installation?.device?.manufacturerNumber || '',
                                },
                                { name: 'Kennzeichen', value: installation?.licenseNumber || '' },
                            ],
                        })),
                    ],
                };
            });
        },
    },
};
</script>
