var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Cars",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "title": "Übersicht Ihrer",
      "highlight": "Taxis"
    }
  }), _c('div', {
    staticClass: "Search"
  }, [_c('Input', {
    attrs: {
      "placeholder": "Fahrzeug suchen",
      "keyName": "query"
    },
    on: {
      "onKeyPress": _vm.handleSearch
    }
  })], 1), _c('h5', {}, [_vm._v("Aktuelle Zuweisungen")]), _c('cars-table', {
    attrs: {
      "cars": _vm.filteredCars.filter(function (car) {
        return car.currentInstallation;
      })
    },
    on: {
      "onCarClick": _vm.handleEditCar,
      "onCarHistoryClick": _vm.handleCarHistoryClick,
      "onCarInformationClick": _vm.handleCarHistoryClick,
      "onCarVisibilityChange": _vm.handleCarVisibilityChange
    }
  }), _c('br'), _c('br'), _vm.filteredCars.filter(function (car) {
    return !car.currentInstallation;
  }) ? _c('div', [_c('h5', {}, [_vm._v("Vorherige Zuweisungen")]), _c('cars-table', {
    attrs: {
      "cars": _vm.filteredCars.filter(function (car) {
        return !car.currentInstallation;
      })
    },
    on: {
      "onCarClick": _vm.handleEditCar,
      "onCarInformationClick": _vm.handleCarHistoryClick,
      "onCarHistoryClick": _vm.handleCarHistoryClick,
      "onCarVisibilityChange": _vm.handleCarVisibilityChange
    }
  })], 1) : _vm._e(), _c('Modal', {
    attrs: {
      "size": "medium",
      "title": _vm.activeCar ? 'Fahrzeug bearbeiten' : 'Fahrzeug hinzufügen',
      "show": _vm.modal.car.isActive,
      "isLoading": _vm.isFetchingData,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSaveCar
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('Section', {
    attrs: {
      "title": "Taxi Informationen"
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('Input', {
    attrs: {
      "keyName": "licenseNumber",
      "label": "Kennzeichen",
      "placeholder": "Kennzeichen",
      "direction": "vertical",
      "isDisabled": true,
      "value": _vm.activeCar && _vm.activeCar.licenseNumber ? _vm.activeCar.licenseNumber : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "value": _vm.activeCar && _vm.activeCar.lastEichungAt ? _vm.activeCar.lastEichungAt : '',
      "label": "Eichung zuletzt am",
      "type": "date",
      "placeholder": "Eichung",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "lastTuvAt",
      "label": "TÜV zuletzt am",
      "placeholder": "TÜV",
      "type": "date",
      "direction": "vertical",
      "value": _vm.activeCar && _vm.activeCar.lastTuvAt ? _vm.activeCar.lastTuvAt : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "lastBokraftAt",
      "label": "BoKraft zuletzt am",
      "placeholder": "BoKraft",
      "type": "date",
      "direction": "vertical",
      "value": _vm.activeCar && _vm.activeCar.lastBokraftAt ? _vm.activeCar.lastBokraftAt : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "wheels",
      "label": "KM Stand letzter Reifenwechsel",
      "placeholder": "Reifen",
      "direction": "vertical",
      "value": _vm.activeCar && _vm.activeCar.wheels ? _vm.activeCar.wheels : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "value": _vm.activeCar && _vm.activeCar.manufacturer ? _vm.activeCar.manufacturer : '',
      "label": "Hersteller",
      "isDisabled": true,
      "placeholder": "Hersteller",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "value": _vm.activeCar && _vm.activeCar.lastOilChange ? _vm.activeCar.lastOilChange : '',
      "label": "KM-Stand letzter Ölwechse",
      "placeholder": "KM-Stand letzter Ölwechse",
      "direction": "vertical"
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "model",
      "label": "Modell",
      "placeholder": "Modell",
      "isDisabled": true,
      "direction": "vertical",
      "value": _vm.activeCar && _vm.activeCar.model ? _vm.activeCar.model : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "label": "Seriennummer",
      "placeholder": "Seriennummer",
      "direction": "vertical",
      "isDisabled": true,
      "value": _vm.activeCar && _vm.activeCar.vin ? _vm.activeCar.vin : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "make",
      "label": "Baujahr",
      "placeholder": "Baujahr",
      "direction": "vertical",
      "isDisabled": true,
      "value": _vm.activeCar && _vm.activeCar.make ? _vm.activeCar.make : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }